<template>
    <div class="flex-grow-1">
    <AdminTitle title="Preferred Teachers">
      <template v-slot:actions>
        <TalentFavoriteFilter @sortOrder="filterOrder" />
      </template>
    </AdminTitle>

    <!-- Filters -->
    <div>
      <preloader v-if="status.getting" />
          
      <div v-if="users.length">
        <teacher-card 
          v-for="user in refined"
          :key="user.id"
          :user="$store.getters['users/userData'](user.talentId)"
        />
      </div>

      <div class="text-center" v-if="!users.length">
        No records found.
      </div>
    </div>
    <!-- End Filters -->

    <!-- INVITE DIALOG -->
    <invite-dialog :user="$store.state.user.user"></invite-dialog>
  </div>
</template>

<script>
import TeacherCard from '@/components/dashboard/talent/TeacherCard'
import TalentFavoriteFilter from '@/components/dashboard/talent/TalentFavoriteFilter'
import { mapState, mapActions } from 'vuex'
import InviteDialog from '@/views/job/InviteDialog'

export default {
  data() {
    return {
      order: 'createdAt',
    }
  },

  components: {
    TeacherCard,
    TalentFavoriteFilter,
    InviteDialog,
  },

/* -------------------------------------------------------------------------- */
/*                                  COMPUTED                                  */
/* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      users: state => state.favorite.talentFavorites,
      status: state => state.favorite.status,
    }),

    refined: function () {
      let refined = this.users.filter(user => {
        let existingUser = this.$store.getters['users/userData'](user.talentId)
        return !!Object.keys(existingUser).length
      })

      return refined
    }

  },

/* -------------------------------------------------------------------------- */
/*                                    WATCH                                   */
/* -------------------------------------------------------------------------- */
  watch: {
    'user' : function(val) {
      if(val) {
        if(val && val.role == 'jobseeker'){
          this.$router.push({ name: 'Jobs' }).catch(()=>{})
        }
      }
    }
  },
/* -------------------------------------------------------------------------- */
/*                                   METHODS                                  */
/* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('favorite', [
      'getTalentFavorites',
    ]),

    filterOrder(order) {
      this.order = order
    },

  },

  mounted() {
    this.getTalentFavorites()
  }
}
</script>