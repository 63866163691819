<template>
  <div class="text-right">
    <v-menu
      :close-on-content-click="false"
      offset-y
      bottom
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="rounded" icon outlined>
          <v-icon>mdi-sort</v-icon>
        </v-btn>  
      </template>

      <v-card width="150">
        <v-card-text>
          <v-radio-group 
            v-model="order"
            @change="sort()"
            class="mt-0"
            hide-details
          >
            <v-radio label="Newest" value="createdAt"></v-radio>
            <v-radio label="Name" value="firstName"></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      order: 'createdAt'
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      status: (state) => state.favorite.status,
    }),
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions("favorite", ["getTalentFavorites", "getRemoteTalentsFav"]),

    sort() {
      this.$emit("sortOrder", { order: this.order });
    },

    clear() {
      this.getTalentFavorites();
    },
  },
};
</script>
